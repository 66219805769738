//
// Dropzone
//

.dropzone {
  border-style: dashed;
  border-radius: 5px;
  border-width: 2px;
  border-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  cursor: pointer;
}

.file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    width: 100%;
  }
}

.file-enter {
  opacity: 0.01;
}

.file-enter.file-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.file-leave {
  opacity: 1;
}

.file-leave.file-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.file-appear {
  opacity: 0.01;
}

.file-appear.file-appear-active {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.dz-message {
  padding: 5rem 1rem;
  background-color: $input-bg;
  border: $input-border-width dashed $input-border-color;
  border-radius: $border-radius;
  text-align: center;
  color: $text-muted;
  transition: $transition-base;
  order: -1;
  cursor: pointer;
  z-index: 999;

  &:hover {
    border-color: $text-muted;
    color: $body-color;
  }
}

.dz-drag-hover .dz-message {
  border-color: theme-color('primary');
  color: theme-color('primary');
}

.dropzone-multiple .dz-message {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.dropzone-single.dz-max-files-reached .dz-message {
  background-color: fade-out($black, 0.1);
  color: white;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.dz-preview-single {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: $border-radius;
}

.dz-preview-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: $border-radius;
}

.dz-preview-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
}

.dz-preview-multiple .list-group-item:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

[data-dz-size] strong {
  font-weight: $font-weight-normal;
}
